import config from '../config'

const ClarityScript = `
(function(c,l,a,r,i,t,y){
c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
t=l.createElement(r);t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${config.pixels.clarity}");`

const FacebookScript = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.defer=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', ${config.pixels.fb});`

const GtagScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${config.pixels.gtag}', { page_path: window.location.pathname });
`

const ConsoleMessage = `
\u2764️ Welcom to SpotMe \u2764

   *****    *****
  ******* ********
 ******************
  ****************
   *************
    **********
      ******
        **

Join us and build a world where anyone can store anywhere at https://www.linkedin.com/company/spotmemx
`

export { ClarityScript, ConsoleMessage, FacebookScript, GtagScript }

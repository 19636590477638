import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'
import config from '../../src/config'
import {
  ClarityScript,
  ConsoleMessage,
  FacebookScript,
  GtagScript
} from '../../src/constants/scripts'
import fbp from '../../src/utils/fbp'
import ga from '../../src/utils/gtag'

function Scripts() {
  const router = useRouter()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(ConsoleMessage)
  }, [])

  useEffect(() => {
    fbp.pageview()

    const handleRouteChange = (url: URL) => {
      fbp.pageview()
      ga.pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {!config.isLocal && (
        <>
          <Script
            id="gtag"
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.pixels.gtag}`}
          />
          <Script
            id="google-tag-manager"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{ __html: GtagScript }}
          />
        </>
      )}
      {config.isProd && (
        <>
          <Script
            id="microsoft-clarity"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{ __html: ClarityScript }}
          />
          <Script
            id="fb-pixel"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{ __html: FacebookScript }}
          />
          <Script
            id="zoho-crm"
            strategy="lazyOnload"
            type="text/javascript"
            src="https://crm.zoho.com/crm/javascript/zcga.js"
          />
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        </>
      )}
    </>
  )
}

export default React.memo(Scripts)
